var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"bodys"},[_c('div',{staticClass:"tabs"},[_c('div',{class:_vm.queryParams.type == 1 ? 'tabPaneGree' : 'tabPane',on:{"click":function($event){return _vm.typeChange(1)}}},[_vm._v(" 开篇 （"+_vm._s(_vm.totalNum)+"） ")]),_c('div',{class:_vm.queryParams.type == 2 ? 'tabPaneGree' : 'tabPane',on:{"click":function($event){return _vm.typeChange(2)}}},[_vm._v(" 续写（"+_vm._s(_vm.renewTotalNum)+"） ")])]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"contentBox",attrs:{"element-loading-spinner":"el-icon-loading","element-loading-background":"rgba(0, 0, 0, 0.5)","element-loading-text":"拼命加载中"}},[(_vm.list.length)?_c('div',{staticClass:"content"},_vm._l((_vm.list),function(item){return _c('div',{key:item.articleId,staticClass:"items"},[_c('div',{class:[
              _vm.queryParams.type == 1 ? 'constItemGee' : 'constItemRed',
              item.offShelf ? 'constItemOp' : '',
            ],on:{"click":function($event){return _vm.goDetail(item)}}},[_c('div',{staticClass:"img"},[(item.publishState == 0)?_c('img',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":require("./images/noImg.png"),"fit":"cover"}}):_c('el-image',{staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":item.imageUrl,"fit":"cover"}}),_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))])],1),_c('div',{staticClass:"center"},[_c('div',{staticClass:"likeItem"},[_c('img',{staticStyle:{"width":"16px","margin-right":"6px"},attrs:{"src":require("./images/write.png")}}),_c('span',[_vm._v(" "+_vm._s(item.renewNum))])]),_c('div',{staticClass:"likeItem"},[_c('img',{staticStyle:{"width":"16px","margin-right":"6px"},attrs:{"src":require("./images/like.png")}}),_c('span',[_vm._v(" "+_vm._s(item.favorNum))])])]),_c('div',{staticClass:"bottom"},[_c('i',{staticClass:"el-icon-alarm-clock",staticStyle:{"font-size":"18px"}}),_c('span',[_vm._v(" "+_vm._s(item.createTime))])])]),_c('div',{class:[
            'foot',
              _vm.queryParams.type == 1 ? 'foot1' : 'foot2',
            ]},[(item.publishState == 0)?_c('span',[_vm._v("作品未发布，暂无收益")]):_c('div',{staticClass:"footBox",on:{"click":function($event){return _vm.goToIncome(item)}}},[_c('img',{staticStyle:{"width":"24px","margin-right":"6px"},attrs:{"src":require("./images/shouyi.png")}}),_c('div',{staticClass:"left"},[_c('div',{staticClass:"money"},[_c('div',[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(" "+_vm._s(item.ethAmount))]),_vm._v("ETH ")]),_c('img',{staticStyle:{"width":"20px","margin-left":"8px"},attrs:{"src":require("@/assets/images/ethBig.png"),"alt":""}})]),_c('div',{staticClass:"money"},[_c('div',[_c('span',{staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(item.daicAmount))]),_vm._v(" DAIC ")]),_c('img',{staticStyle:{"width":"20px","margin-left":"8px"},attrs:{"src":require("@/components/Header/images/btc.png"),"alt":""}})])])])])])}),0):_c('div',{staticClass:"noContent"},[_c('img',{attrs:{"src":require("./images/noData.png"),"alt":""}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }